import { createStyles, Theme } from "@material-ui/core/styles";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      width: "800px",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(10),
      [theme.breakpoints.down("md")]: {
        marginTop: theme.spacing(6),
      },
      [theme.breakpoints.down("sm")]: {
        width: "100vw",
        maxWidth: "800px",
      },
    },
    linkToBlog: {
      display: "flex",
      alignItems: "center",
      marginBottom: theme.spacing(5),
    },
    iconBack: {
      marginRight: theme.spacing(2),
    },
    mainImage: {
      width: "100%",
      objectFit: "contain",
      marginBottom: theme.spacing(6),
      borderRadius: "4px",
    },
    category: {
      marginLeft: theme.spacing(-1),
      marginBottom: theme.spacing(3),
    },
    title: {
      marginBottom: theme.spacing(1),
    },
    author: {
      display: "flex",
      alignItems: "center",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    authorAvatar: {
      marginRight: theme.spacing(2),
    },
    fullName: {
      marginBottom: theme.spacing(0.5),
    },
    content: {
      "& p": {
        margin: 0,
      },
      "& *": {
        ...theme.typography.body1,
        lineHeight: 1.75,
        fontWeight: "inherit",
        [theme.breakpoints.down("xs")]: {
          ...theme.typography.body2,
          lineHeight: 1.71,
          letterSpacing: "normal",
        },
      },
      "& h5": {
        margin: theme.spacing(1, 0),
      },
      "& strong": {
        fontWeight: 500,
      },
    },
    date: {},
  });
